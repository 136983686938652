var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('UserTopNav'),_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.status_items,"label":"Status","dense":"","solo":""},on:{"change":function($event){return _vm.getAlarm()}},model:{value:(_vm.selected_status),callback:function ($$v) {_vm.selected_status=$$v},expression:"selected_status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v("Reset")])],1),_c('v-spacer')],1)],1)],1),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.data_alarm,"loading":_vm.loading,"items-per-page":_vm.pagination.rowsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.created_at)))])]}},{key:"item.affected_node",fn:function(ref){
var item = ref.item;
return _vm._l((item.affected_node),function(items){return _c('div',[(item.affected_node.length === 0)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(items.id)+" - "+_vm._s(items.coordinate)+"° ")])])})}},{key:"item.affected_segment",fn:function(ref){
var item = ref.item;
return _vm._l((item.affected_segment),function(items){return _c('div',[(item.affected_segment.length === 0)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(items.id)+" - "+_vm._s(items.coordinate_ne)+"° : "+_vm._s(items.coordinate_fe)+"°")])])})}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [(item.severity === 5)?_c('v-chip',{staticClass:"pmt-chip color-gray-light"},[_vm._v("Not Yet Identified")]):(item.severity === 1)?_c('v-chip',{staticClass:"pmt-chip color-red-light"},[_vm._v("Critical")]):(item.severity === 2)?_c('v-chip',{staticClass:"pmt-chip color-orange-light"},[_vm._v("Major")]):(item.severity === 3)?_c('v-chip',{staticClass:"pmt-chip color-yellow-light"},[_vm._v("Medium")]):(item.severity === 4)?_c('v-chip',{staticClass:"pmt-chip color-green-light"},[_vm._v("Minor")]):_vm._e()]}},{key:"item.target_to_resolved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.target_to_resolved)+" Hour ")]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeFormat(item.start_time))+" ")]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [(item.hasOwnProperty('end_time') != false)?_c('span',[_vm._v(" "+_vm._s(_vm.timeFormat(item.end_time))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-5"},[_c('img',{attrs:{"height":"300px","src":require("../../../assets/image/empty.svg")}}),_c('div',[_vm._v("No Data")]),_c('v-btn',{staticClass:"mt-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v("Reset")])],1)]},proxy:true}],null,true)}),_c('v-container',{staticClass:"max-width"},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{staticClass:"item-per-page",attrs:{"items":_vm.pagination.rowsPerPageItems},on:{"change":function($event){return _vm.getAlarmByTableRow()}},model:{value:(_vm.pagination.rowsPerPage),callback:function ($$v) {_vm.$set(_vm.pagination, "rowsPerPage", $$v)},expression:"pagination.rowsPerPage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"my-4",attrs:{"total-visible":7,"length":_vm.pagination.totalItems},on:{"input":function($event){return _vm.getAlarm()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1),_c('v-overlay',{attrs:{"z-index":999,"value":_vm.loading_overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }